li.css-1p308co::before {
	border-top: 2px dashed #e7e7e7;
}

li.css-1p308co::after {
	border-left: 2px dashed #e7e7e7;
	border-top: 2px dashed #e7e7e7;
}

ul.css-1jae07r::before {
	border-left: 2px dashed #e7e7e7;
}

li.css-1p308co:last-of-type::before {
	border-right: 2px dashed #e7e7e7;
}
