#grid {
	border-collapse: collapse;
}

#grid th,
#grid td {
	border: 1px solid #b8b8b8;
	white-space: nowrap;
}
table {
	/* width: 100%; */
	text-align: center;
}

#grid td {
	background-color: #fff;
	padding: 5px;
	min-width: 30px;
	min-height: 20px;
	cursor: pointer;
}

tr {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

td.selected {
	background-color: rgb(175, 183, 226); /* !important; */
}

/* td.selected.empty {
	background-color: rgb(175, 183, 226) ;
	-webkit-box-shadow: inset 0px 0px 0px 1px #000 !important;
	-moz-box-shadow: inset 0px 0px 0px 1px #000 !important;
	box-shadow: inset 0px 0px 0px 1px #000 !important;
} */

td.active {
	/* border: 2px solid #183770 */
	-webkit-box-shadow: inset 0px 0px 0px 1px #000;
	-moz-box-shadow: inset 0px 0px 0px 1px #000;
	box-shadow: inset 0px 0px 0px 1px #000;
}

table .header {
	background-color: #eeecec;
}

.datasheetContainer {
	height: 93%;
	overflow: auto;
}

.fullHeight {
	height: 100%;
	width: 100%;
}

/* width */

::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}

/* Track */

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: #3e64ad;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.gridContainer {
	background-color: #e9e7e7;
	margin: 5px;
	vertical-align: top;
	padding: 10px 5px;
	box-shadow: 0px 1px 12px #3e64ad;
	border-radius: 14px;
}

.sheetContainer {
	margin: 50px;
}

.value-viewer {
	padding: 5px;
}

.file {
	display: block;
}

.datasheetTable {
	min-width: 30%;
	/* max-width: 48%; */
	/* margin: 20px 5px; */
	float: left;
}

.tables {
	width: 100%;
	white-space: nowrap;
	/* height: 50vh; */
	/* height: 100%; */
	display: inline-block;
}

.sheet {
	display: inline-block;
	overflow: auto;
	max-height: 38vh;
	width: 100%;
	border-radius: 14px;
}

.tableDivRight {
	display: inline-block;
	overflow: auto;
	height: 60vh;
	width: 46%;
}

.controlFormating {
	width: 6%;
	display: inline-block;
}

.data-editor {
	height: 100% !important;
}

.border-top {
	border-top: 4px red solid !important;
}

.border-bottom {
	border-bottom: 4px red solid !important;
}

.border-left {
	border-left: 4px red solid !important;
}

.border-right {
	border-right: 4px red solid !important;
}

#grid thead th {
	background-color: #f3f3f3;
	transition: background-color 500ms ease;
	vertical-align: middle;
	text-align: center;
	border: 1px solid #ddd;
	padding: 0;
}

.cell {
	text-align: center !important;
	min-width: 30px;
	vertical-align: middle !important;
	height: 100%;
}

.data-grid-container .data-grid .cell.read-only {
	background-color: #fff !important;
	color: #000 !important;
}

.cell {
	height: 3vh !important;
}

.tabsSheet {
	padding: 5px;
	margin: 5px;
	display: inline-block;
}

.sheetTab {
	font-size: 0.8rem;
	margin: 5px !important;
	padding: 2px;
	border: 1px solid #3e64ad;
	color: #3e64ad;
	font-weight: bold;
	cursor: pointer;
	display: inline-block;
}

.sheetTab.active {
	margin: 4px;
	padding: 2px;
	border: 1px solid #3e64ad;
	background-color: #3e64ad;
	color: #fff;
	cursor: pointer;
}

.sheetTab.disabled {
	border: 1px solid #a1a1a1;
	color: #a1a1a1;
}

.rowHeader {
	text-align: center;
}

.mask {
	background-color: #e67e30;
}
