.accountTable {
	table-layout: fixed;
	width: 50%;
	border-collapse: collapse;
	border: 1px solid #8b9898;
	background-color: #e7e7e7;
}

.accountTable-th {
	border-width: 1px;
	border-style: solid;
	border-color: #8b9898;
	width: 50%;
}
